import * as React from 'react'
// material
import { Container } from '@mui/material';
// components
import HomePage from '../components/heroes/HomePage';
import TitlePageBlock from '../components/heroes/TitlePageBlock';
import TextPageBlock from '../components/heroes/TextPageBlock';

// ----------------------------------------------------------------------

export default function LandingPage() {
  return (
    <HomePage title="Heroes">
      <TitlePageBlock title="Heroes" subTitle="TODO: Write text about heroes" imgUrl="https://uxwing.com/wp-content/themes/uxwing/download/42-business-professional-services/company-enterprise.png"/>
      <Container maxWidth="lg">
        <TextPageBlock title="Info1" subTitle="Description">test content ...</TextPageBlock>
      </Container>
    </HomePage>
  );
}
/*import * as React from 'react'
// material
import { styled } from '@mui/material/styles';
// components
import Page from '../components/Page';
import {
  LandingHero,
  LandingMinimal,
  LandingDarkMode,
  LandingThemeColor,
  LandingPricingPlans,
  LandingAdvertisement,
  LandingCleanInterfaces,
  LandingHugePackElements
} from '../components/_external-pages/landing';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)({
  height: '100%'
});

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function LandingPage() {
  return (
    <RootStyle title="Home" id="move_top">
      <LandingHero />
      <ContentStyle>
        <LandingMinimal />
        <LandingHugePackElements />
        <LandingDarkMode />
        <LandingThemeColor />
        <LandingCleanInterfaces />
        <LandingPricingPlans />
        <LandingAdvertisement />
      </ContentStyle>
    </RootStyle> 
  );
}*/