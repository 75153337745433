import * as React from 'react'
// material
import { Typography, Grid } from '@mui/material';
//

// ----------------------------------------------------------------------

export default function TextPageBlock({title="Title", subTitle="Subtitle", children}) {
  return (
    <Grid container spacing={3} sx={{ my: 10 }}>
      <Grid item xs={12} sm={4}>
        <Typography variant="h5" paragraph>
          {title}
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          {subTitle}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={8}>
        {children}
      </Grid>
    </Grid>
  );
}
